<template>
  <div class="relative">
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
        <div class="absolute inset-0">
          <div
            class="absolute inset-0 bg-gradient-to-r from-purple-900 to-indigo-800 mix-blend-multiply"
          />
        </div>
        <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
          <h1
            class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl"
          >
            <span class="block text-white">{{ $t('About.TDApp') }}</span>
          </h1>
          <p
            class="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-100 sm:max-w-3xl"
          >
            {{ $t('About.Description') }}
          </p>
          <div
            class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center"
          >
            <a
              href="https://tdapp.org/"
              class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
            >
              {{ $t('About.MoreInfo') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
